import { Button } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInputText } from 'srs.sharedcomponents/lib/esm/components/srs.formInputText.component'
import { FormInputCheckbox } from 'srs.sharedcomponents/lib/esm/components/srs.formInputCheckbox.component'
import { FormPasswordRules } from 'srs.sharedcomponents/lib/esm/components/srs.formPasswordRules.component'
import { FormInputPassword } from 'srs.sharedcomponents/lib/esm/components/srs.formInputPassword.component'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { i18nNamespaces as namespace } from 'srs.sharedcomponents/lib/esm/i18n/i18n'
import { useHistory } from 'react-router'
import Swal from 'sweetalert2'
import { useAppDispatch, useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'
import {
  clearRegistrationError,
  enhancedInitial,
  getEnxhancedInitial,
  getProvinces,
  registerUser,
  registrationError,
  registrationStatus,
} from 'srs.sharedcomponents/lib/esm/redux/slices/registrationSlice'
import { stateStatus } from 'srs.sharedcomponents/lib/esm/utils/enums'
import { bearerToken, userCountry } from 'srs.sharedcomponents/lib/esm/redux/slices/userSlice'
import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import LoadingIndicator from 'srs.sharedcomponents/lib/esm/components/LoadingIndicator'
import CustomerDataComponent from 'srs.sharedcomponents/lib/esm/components/srs.customerData.component'
import { stringToBoolean } from 'srs.sharedcomponents/lib/esm/utils/helpers'
import ChoseEnhancedRegistrationType from 'srs.sharedcomponents/lib/esm/components/srs.choseEnhancedRegistrationType'
import CustomerDataExistingComponent from 'srs.sharedcomponents/lib/esm/components/srs.customerDataExisting.component'
import { IDropDownOption } from 'srs.sharedcomponents/lib/esm/models/srs.formDropdownOption.model'
import { FormInputDropdown } from 'srs.sharedcomponents/lib/esm/components/srs.formInputDropdown.component'
import { EnhancedRegistrationInitial } from 'srs.sharedcomponents/lib/esm/models/responses/srs.EnhancedRegistrationInitial.model'
import { siteSettings } from 'srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice'
import { ISiteSettings } from 'srs.sharedcomponents/lib/esm/models/srs.siteSettings'

import schemas from './schemas'

const RegistrationComponent = () => {
  const { t } = useTranslation()
  const htmlPrivacyPolicy =
    t(`Registration_PrefixTermsandConditions`, { ns: namespace.coreSrsPrimary }) +
    '<a href="/getcorporateinformation?privacypolicy" class="box-links" id="privacyPolicyLink"> ' +
    t(`Footer_PrivacyText`, { ns: namespace.coreSrsPrimary }) +
    '.</a>'

  const [enhancedRegistrationType, setEnhancedRegistrationType] = useState(1)
  const htmlTerms =
    t(`Registration_PrefixTermsandConditions`, { ns: namespace.coreSrsPrimary }) +
    ' <a href="/getcorporateinformation?termsandconditionsofuse" class="box-links" id="termsAndConditionsLink"> ' +
    t(`Terms and Conditions of Use`, { ns: namespace.coreSrsPrimary }) +
    '.</a>'
  const settings: ISiteSettings | undefined = useAppSelector(siteSettings)

  const userCountr = useAppSelector(userCountry)
  const enhancedInit: EnhancedRegistrationInitial | undefined = useAppSelector(enhancedInitial)

  const isEnhancedRegistration =
    stringToBoolean(settings?.['Srs.EnableEnhancedRegistrationPerWebsite']) &&
    !enhancedInit?.disableEnhancedRegistration

    const enableNewsLetterConsent = stringToBoolean(settings?.['SRS.EnableNewsletterConsent'])

  const DisableCustomerPanelForEnhanceReg = stringToBoolean(
    settings?.['Srs.DisableCustomerPanelForEnhanceReg'],
  )
  interface IFormInput {
    mail: string
    confirmEmail: string
    firstName: string
    middleName: string
    lastName: string
    password: string
    confirmPassword: string
    securityQuestion: string
    securityAnswer: string
    retailerCode: string
    retailerZip: string
    privacyPolicy: boolean
    terms: boolean
    newsletter: boolean
  }
  interface IFormInputExended extends IFormInput {
    companyName: string
    militaryCustomer: string
    brand: string
    oemDealerCode: string
    vatNumber: string
    siretNumber: string
    // Billing part.
    billingStreet: string
    billingNumberName: string
    billingPostalCode: string
    billingCity: string
    billingPOBox: string
    billingPOPostalCode: string
    billingSelectedCountry: string
    billingSelectedRegion: string
    sameAsBilling: boolean
    // Shipping part.
    shippingStreet: string
    shippingNumberName: string
    shippingPostalCode: string
    shippingCity: string
    shippingSelectedCountry: string
    shippingSelectedRegion: string
    shortOemDealerCode: string
    shortSelectedCountry: string
    // Additional info
    phonePrefix: string
    phone: string
    title: string
    behalfOfCompany: boolean
    corectData: boolean
  }
  const defaultBillingValues = {
    billingStreet: '',
    billingNumberName: '',
    billingPostalCode: '',
    billingCity: '',
    billingPOBox: '',
    billingPOPostalCode: '',
    billingSelectedCountry: '',
    billingSelectedRegion: '',
    sameAsBilling: false,
  }
  const defaultShippingValues = {
    shippingStreet: '',
    shippingNumberName: '',
    shippingPostalCode: '',
    shippingCity: '',
    shippingSelectedCountry: '',
    shippingSelectedRegion: '',
    shortOemDealerCode: '',
    shortSelectedCountry: userCountr?.toString(),
  }
  const additionalInfo = {
    phonePrefix: '',
    phone: '',
    title: '',
    behalfOfCompany: false,
    corectData: false,
  }
  const defaultValues = {
    error: t(`MandatoryField1`, { ns: namespace.coreSrsPrimary }),
    mail: '',
    confirmEmail: '',
    firstName: '',
    middleName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    securityQuestion: '',
    securityAnswer: '',
    retailerCode: '',
    retailerZip: '',
    privacyPolicy: false,
    terms: false,
    newsletter: false,
    companyName: '',
    militaryCustomer: 'No',
    brand: '',
    oemDealerCode: '',
    vatNumber: '',
    siretNumber: '',
    ...defaultShippingValues,
    ...defaultBillingValues,
    ...additionalInfo,
  }

  const defaultSchema = yup.object().shape(schemas.registration())

  let schema = defaultSchema
  const billingSchema = yup.object().shape({
    sameAsBilling: yup.boolean(),
    companyName: yup
      .string()
      .required(t(`CreateUserCustomerNameValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    militaryCustomer: yup.string(),
    brand: yup
      .string()
      .required(t(`CreateUseBrandValidatorErrorMsg`, { ns: namespace.coreSrsPrimary })),
    oemDealerCode: yup.string(),
    vatNumber: yup
      .string()
      .required(t(`CreateUseVATValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    siretNumber: yup.string().when('billingSelectedCountry', {
      is: enhancedInit.siretCountry && enhancedInit.siretCountry.toString(),
      then: yup
        .string()
        .required(t(`CreateUserSIERTValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    }),
    billingStreet: yup
      .string()
      .required(t(`CreateUserBAStreet1ValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    billingNumberName: yup
      .string()
      .required(t(`CreateUserBuildingNumberValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    billingPostalCode: yup
      .string()
      .required(t(`CreateUserBAPostalCodeValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    billingCity: yup
      .string()
      .required(t(`CreateUserBACityValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    billingPOBox: yup.string(),
    billingPOPostalCode: yup.string(),
    billingSelectedCountry: yup
      .string()
      .required(t(`valMissingCountry`, { ns: namespace.coreSrsPrimary })),
    billingSelectedRegion: yup.string(),
  })
  const shippingSchema = yup.object().shape({
    shippingStreet: yup.string().when('sameAsBilling', {
      is: false,
      then: yup
        .string()
        .required(t(`CreateUserSAStreet1ValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    }),
    shippingNumberName: yup.string().when('sameAsBilling', {
      is: false,
      then: yup
        .string()
        .required(t(`CreateUserSABuildingNumberValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    }),
    shippingPostalCode: yup.string().when('sameAsBilling', {
      is: false,
      then: yup
        .string()
        .required(t(`CreateUserBAPostalCodeValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    }),
    shippingCity: yup.string().when('sameAsBilling', {
      is: false,
      then: yup
        .string()
        .required(t(`CreateUserSACityValidatorMsg`, { ns: namespace.coreSrsPrimary })),
    }),
    shippingSelectedCountry: yup.string().when('sameAsBilling', {
      is: false,
      then: yup.string().required(t(`valMissingCountry`, { ns: namespace.coreSrsPrimary })),
    }),
    shippingSelectedRegion: yup.string(),
  })
  const existingAccountSchema = yup.object().shape({
    shortOemDealerCode: yup
      .string()
      .required(t(`DealershipidEnhanceValidatorErrorMsg`, { ns: namespace.coreSrsPrimary })),
    shortSelectedCountry: yup.string(),
  })

  const additionalInfoSchema = yup.object().shape({
    phonePrefix: yup
      .string()
      .required(
        t(`CreateUserConfirmCountryCodeValidatorErrorMsg`, { ns: namespace.coreSrsPrimary }),
      ),
    phone: yup
      .string()
      .required(t(`CreateUserConfirmMobileValidatorErrorMsg`, { ns: namespace.coreSrsPrimary })),
    title: yup
      .string()
      .required(
        t(`CreateUserConfirmSalutationValidatorErrorMsg`, { ns: namespace.coreSrsPrimary }),
      ),
    behalfOfCompany: yup
      .bool()
      .required('Required')
      .oneOf(
        [true],
        t(`CreateUserBehalfoFCustomerValidatorMsg`, {
          ns: namespace.coreSrsPrimary,
        }),
      ),
    corectData: yup
      .bool()
      .required('Required')
      .oneOf(
        [true],
        t(`CreateUserValidDataValidatorMsg`, {
          ns: namespace.coreSrsPrimary,
        }),
      ),
  })
  if (isEnhancedRegistration && enhancedRegistrationType === 1) {
    schema = defaultSchema
    schema = schema.concat(additionalInfoSchema)
    schema = schema.concat(existingAccountSchema)
  }
  if (isEnhancedRegistration && enhancedRegistrationType === 2) {
    schema = defaultSchema
    schema = schema.concat(additionalInfoSchema)
    schema = schema.concat(billingSchema)
    schema = schema.concat(shippingSchema)
  }

  const methods = useForm<IFormInputExended>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'all',
  })

  const { handleSubmit, control, watch, setValue } = methods
  const history = useHistory()
  const dispatch = useAppDispatch()

  const status = useAppSelector(registrationStatus)
  const err = useAppSelector(registrationError)
  const bearer = useAppSelector(bearerToken)

  if (status === stateStatus.failed && err.length) {
    Swal.fire({
      title: err,
      icon: 'error',
    }).then(function () {
      dispatch(clearRegistrationError())
    })
  }

  useEffect(() => {
    dispatch(clearRegistrationError())
    if (bearer) history.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, dispatch])

  useEffect(() => {
    if (status === stateStatus.success) {
      Swal.fire({
        title: t(`UserSucessfullyCreatedHeader`, {
          ns: namespace.coreSrsPrimary,
        }),
        html:
          isEnhancedRegistration &&
          t(`ActivateUser_Finalization_With_ExistingCompany`, {
            ns: namespace.coreSrsPrimary,
          }) +
          t(`ActivationMessageForNewCustomer`, {
            ns: namespace.coreSrsPrimary,
          }),
        icon: 'success',
      }).then(function () {
        dispatch(clearRegistrationError())
        history.push('/login')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const onSubmit = (data: IFormInputExended) => {
    if (!isEnhancedRegistration) {
      dispatch(
        registerUser({
          UserInfo: {
            LoginName: data.mail,
            Email: data.mail,
            FirstName: data.firstName,
            MiddleName: data.middleName,
            LastName: data.lastName,
            Password: data.password,
            RecoverQuestion: data.securityQuestion,
            RecoverAnswer: data.securityAnswer,
            DealershipID: data.retailerCode,
            DealershipZipCode: data.retailerZip,
            IsNewsletterChecked: data.newsletter,
          },
          OrganizationInfo: null,
        }),
      )
    } else if (enhancedRegistrationType === 1) {
      dispatch(
        registerUser({
          UserInfo: {
            LoginName: data.mail,
            Email: data.mail,
            FirstName: data.firstName,
            MiddleName: data.middleName,
            LastName: data.lastName,
            Password: data.password,
            RecoverQuestion: data.securityQuestion,
            RecoverAnswer: data.securityAnswer,
            IsNewsletterChecked: data.newsletter,
            IsEnhancedRegistration: isEnhancedRegistration,
            DealershipID: data.shortOemDealerCode,
            ISOCountryID: Number(data.shortSelectedCountry),
            UserExtension: {
              SalutationTypeId: Number(data.title),
              MobileNumber: data.phone,
              BehalfOfCompanyAccepted: data.behalfOfCompany,
              OrderOnBehalfOfCompanyAccepted: data.corectData,
              TermsAndConditionsAccepted: data.terms,
              ISOCountryIDMobile: Number(data.phonePrefix),
            },
          },
          OrganizationInfo: null,
        }),
      )
    } else {
      dispatch(
        registerUser({
          UserInfo: {
            LoginName: data.mail,
            Email: data.mail,
            FirstName: data.firstName,
            MiddleName: data.middleName,
            LastName: data.lastName,
            Password: data.password,
            RecoverQuestion: data.securityQuestion,
            RecoverAnswer: data.securityAnswer,
            IsNewsletterChecked: data.newsletter,
            IsEnhancedRegistration: isEnhancedRegistration,
            ISOCountryID: Number(data.shortSelectedCountry),
            UserExtension: {
              SalutationTypeId: Number(data.title),
              MobileNumber: data.phone,
              BehalfOfCompanyAccepted: data.behalfOfCompany,
              OrderOnBehalfOfCompanyAccepted: data.corectData,
              TermsAndConditionsAccepted: data.terms,
              ISOCountryIDMobile: Number(data.phonePrefix),
            },
          },
          OrganizationInfo: {
            Addresses: [
              {
                City: data.billingCity,
                Country: getThreeLetterCodeForCountry(data.billingSelectedCountry),
                Postcode: data.billingPostalCode,
                Region: data.billingSelectedRegion,
                Street: data.billingStreet,
                Street_No: data.billingNumberName,
                POBox: data.billingPOBox,
                POBoxPostalCode: data.billingPOPostalCode,
                IsBillingAddress: true,
              },
              ...(!data.sameAsBilling
                ? [
                  {
                    City: data.shippingCity,
                    Country: getThreeLetterCodeForCountry(data.shippingSelectedCountry),
                    Postcode: data.shippingPostalCode,
                    Region: data.shippingSelectedRegion,
                    Street: data.shippingStreet,
                    Street_No: data.shippingNumberName,
                    IsBillingAddress: false,
                  },
                ]
                : []),
            ],
            Brand: data.brand,
            Name: data.companyName,
            MilitaryUsage:
              data.militaryCustomer === t(`YesLabel`, { ns: namespace.coreSrsPrimary }),
            Vat_Number: data.vatNumber,
            SiretNumber: data.siretNumber,
          },
        }),
      )
    }
  }

  useEffect(() => {
    if (stringToBoolean(settings?.['Srs.EnableEnhancedRegistrationPerWebsite'])) {
      dispatch(getEnxhancedInitial())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isEnhancedRegistration])

  useEffect(() => {
    if (userCountr && enhancedInit?.enhancedCountries?.some((c) => c.id === userCountr))
      setValue('billingSelectedCountry', userCountr.toString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enhancedInit.enhancedCountries])

  const countries = enhancedInit.countries

  const titleOptions: IDropDownOption[] = enhancedInit?.salutations?.map((item) => {
    return { label: item.salutationName, value: item.salutationTypeId.toString() }
  })

  const existingCountryOptions: IDropDownOption[] = enhancedInit?.countries?.map((item) => {
    return { label: item.name, value: item.id.toString() }
  })

  const enhancedCountryOptions: IDropDownOption[] = enhancedInit?.enhancedCountries?.map((item) => {
    return { label: item.name, value: item.id.toString() }
  })
  const regionOptions: IDropDownOption[] = enhancedInit?.provinces?.map((item) => {
    return { label: item.name, value: item.id.toString() }
  })
  const phoneCodeOptions: IDropDownOption[] = enhancedInit?.phoneCodes?.map((item) => {
    return { label: item.callingCode + ' ' + item.name, value: item.id.toString() }
  })
  const billSelectedCountry = useWatch({
    control,
    name: 'billingSelectedCountry',
  })
  useEffect(() => {
    setValue('shippingSelectedCountry', billSelectedCountry)
    if (countries) {
      const selectedCountry = countries.find(
        (country) => country.id.toString() === billSelectedCountry,
      )
      if (selectedCountry) {
        dispatch(
          getProvinces({
            country: selectedCountry,
          }),
        )
      }
    }
  }, [billSelectedCountry, countries, dispatch, setValue])

  function getThreeLetterCodeForCountry(_id: string) {
    if (countries) {
      return countries.find((country) => country.id.toString() === _id)?.threeLetterCode
    }
  }
  function renderComponent() {
    if (!isEnhancedRegistration) return true
    if (enhancedInit && titleOptions && enhancedCountryOptions) return true
    return false
  }

  let CorrectDealercodeFormat = t(`CorrectDealercodeFormat`, { ns: namespace.coreSrsPrimary })

  return (
    <Container>
      <LoadingIndicator />
      {renderComponent() && (
        <main id="main" role="main" className="registration">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="col-12 col-sm-12 col-md-12">
                <div className="text-left">
                  <span className="control-label asterix">
                    {t(`MandatoryField2`, { ns: namespace.coreSrsPrimary })}
                  </span>
                </div>
              </Col>
            </Row>
            {/* <p>{salutations[0].salutationName}</p> */}
            <Row>
              <Col md={6} sm={6}>
                <FormInputText
                  id="registrationEmail"
                  name="mail"
                  control={control}
                  schema={schema}
                  label={t(`UserNameLabelText`, { ns: namespace.coreSrsPrimary })}
                  autoComplete={'username'}
                />
              </Col>
              <Col md={6} sm={6}>
                <FormInputText
                  id="registrationConfirmEmail"
                  name="confirmEmail"
                  control={control}
                  schema={schema}
                  label={t(`CreateUserConfirmEmailLabel`, { ns: namespace.coreSrsPrimary })}
                />
              </Col>
            </Row>
            {isEnhancedRegistration && (
              <Row>
                <Col md={3} sm={3}>
                  <FormInputDropdown
                    id="phonePrefix"
                    name="phonePrefix"
                    control={control}
                    schema={schema}
                    label={t(`txtMobileNumberPrefixLabel`, { ns: namespace.coreSrsPrimary })}
                    options={phoneCodeOptions}
                  />
                </Col>
                <Col md={5} sm={5}>
                  <FormInputText
                    id="phone"
                    name="phone"
                    control={control}
                    schema={schema}
                    allowSpecialCharacters
                    label={t(`txtMobileNumberLabel`, { ns: namespace.coreSrsPrimary })}
                  />
                </Col>
                <Col md={4} sm={4}>
                  <FormInputDropdown
                    id="title"
                    name="title"
                    control={control}
                    schema={schema}
                    label={t(`txtSalutationLabel`, { ns: namespace.coreSrsPrimary })}
                    options={titleOptions}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={4} sm={4}>
                <FormInputText
                  id="registrationFirstName"
                  name="firstName"
                  control={control}
                  schema={schema}
                  label={t(`CreateUserFNLabel`, { ns: namespace.coreSrsPrimary })}
                />
              </Col>
              <Col md={4} sm={4}>
                <FormInputText
                  id="registrationMiddleName"
                  name="middleName"
                  control={control}
                  schema={schema}
                  label={t(`CreateUserMNLabel`, { ns: namespace.coreSrsPrimary })}
                />
              </Col>
              <Col md={4} sm={4}>
                <FormInputText
                  id="registrationLastName"
                  name="lastName"
                  control={control}
                  schema={schema}
                  label={t(`CreateUserLNLabel`, { ns: namespace.coreSrsPrimary })}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={6}>
                <FormInputPassword
                  id="registrationPasswordField"
                  name="password"
                  type="password"
                  control={control}
                  schema={schema}
                  label={t(`CreateUserPWDLabel`, { ns: namespace.coreSrsPrimary })}
                />
              </Col>
              <Col md={6} sm={6}>
                <FormInputPassword
                  id="registrationConfirmPasswordField"
                  name="confirmPassword"
                  type="password"
                  control={control}
                  schema={schema}
                  label={t(`CreateUserConfirmPWDLabel`, { ns: namespace.coreSrsPrimary })}
                />
              </Col>
            </Row>
            <Row>
              <FormPasswordRules name="pass" control={control} password={watch('password')} />
            </Row>
            <Row className="align-items-end">
              <Col md={6} sm={6}>
                <FormInputText
                  id="registrationSecurityQuestion"
                  name="securityQuestion"
                  control={control}
                  schema={schema}
                  autoComplete="off"
                  label={t(`CreateUserSecurityQuestionLabel`, { ns: namespace.coreSrsPrimary })}
                />
              </Col>
              <Col md={6} sm={6}>
                <FormInputText
                  id="registrationSecurityAnswer"
                  name="securityAnswer"
                  control={control}
                  schema={schema}
                  autoComplete="off"
                  label={t(`CreateUserSecurityAnswerLabel`, { ns: namespace.coreSrsPrimary })}
                />
              </Col>
            </Row>

            <Row className="align-items-end">
              {!isEnhancedRegistration && (
                <>
                  <Col md={6} sm={6}>
                    <FormInputText
                      id="registrationRetailerCode"
                      name="retailerCode"
                      control={control}
                      schema={schema}
                      label={t(`RetailerCode`, { ns: namespace.coreSrsPrimary })}
                    />
                  </Col>
                  <Col md={6} sm={6}>
                    <FormInputText
                      id="registrationRetailerZip"
                      name="retailerZip"
                      control={control}
                      schema={schema}
                      label={t(`RetailerZipCode`, { ns: namespace.coreSrsPrimary })}
                    />
                  </Col>
                  {CorrectDealercodeFormat && (
                    <p>{t(`CorrectDealercodeFormat`, { ns: namespace.coreSrsPrimary })}</p>
                  )}
                </>
              )}
              {isEnhancedRegistration && (
                <>
                  <FormInputCheckbox
                    id="behalfOfCompany"
                    name="behalfOfCompany"
                    control={control}
                    schema={schema}
                    label={t(`Registration_BehalfofCompany`, { ns: namespace.coreSrsPrimary })}
                  />
                  <FormInputCheckbox
                    id="corectData"
                    name="corectData"
                    control={control}
                    schema={schema}
                    label={t(`Registration_OrderBehalfofCompany`, {
                      ns: namespace.coreSrsPrimary,
                    })}
                  />
                </>
              )}
              <FormInputCheckbox
                id="registrationPrivacyPolicy"
                name="privacyPolicy"
                control={control}
                schema={schema}
                label={<div dangerouslySetInnerHTML={{ __html: htmlPrivacyPolicy }} />}
              />
              <FormInputCheckbox
                id="registrationTerms"
                name="terms"
                control={control}
                schema={schema}
                label={<div dangerouslySetInnerHTML={{ __html: htmlTerms }} />}
              />
              { enableNewsLetterConsent && <FormInputCheckbox
                id="registrationNewsletter"
                name="newsletter"
                control={control}
                schema={schema}
                label={t(`Checkout_OptOutNewsLetters`, { ns: namespace.coreSrsPrimary })}
              /> }
              {isEnhancedRegistration && (
                <div className="p-2 m-2 border rounded" id="customerDataSection">
                  <label className="m-0">
                    <b>{t(`CustomerDataHeaderLabel`, { ns: namespace.coreSrsPrimary })}</b>
                  </label>
                  {!DisableCustomerPanelForEnhanceReg && (
                    <ChoseEnhancedRegistrationType
                      step={enhancedRegistrationType}
                      setStep={setEnhancedRegistrationType}
                    />
                  )}
                  {enhancedRegistrationType === 1 && (
                    <CustomerDataExistingComponent
                      schema={schema}
                      control={control}
                      countryOptions={existingCountryOptions}
                    />
                  )}
                  {enhancedRegistrationType === 2 && (
                    <CustomerDataComponent
                      schema={schema}
                      control={control}
                      watch={watch}
                      countryOptions={enhancedCountryOptions}
                      regionOptions={regionOptions}
                      siretCountry={enhancedInit.siretCountry.toString()}
                    />
                  )}
                </div>
              )}

              <Col md={4} sm={4}>
                <div className="form-group">
                  <Button
                    id="registrationSubmitButton"
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    fullWidth
                    size="large"
                    data-testid="submit"
                  >
                    {t(`MemberLoginRegisterNewText`, { ns: namespace.coreSrsPrimary })}
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </main>
      )}
    </Container>
  )
}
export default RegistrationComponent
