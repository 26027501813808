import { useEffect, useState } from "react";
import { isCurrentCountryIndependent } from "srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice";
import { useAppSelector } from "srs.sharedcomponents/lib/esm/redux/hooks";
import { stringToBoolean } from "srs.sharedcomponents/lib/esm/utils/helpers";
import { useTranslate } from "srs.sharedcomponents/lib/esm/hooks/useTranslate";
export const useConditionalRoutes = () => {
  const { translate } = useTranslate();
  const [conditionalRoutes, setConditionalRoutes] = useState({});
  const isCurrCountryIndependent = useAppSelector(isCurrentCountryIndependent);
  const checkForIndependance = stringToBoolean(
    process.env.REACT_APP_CHECK_FOR_COUNTRY_INDEPENDENCE
  );
  

  useEffect(()=>{
    if (!checkForIndependance || isCurrCountryIndependent) {
      setConditionalRoutes((prev) => ({
        ...prev,
        "/registration": {
          path: "/registration",
          component: "RegistrationComponent",
          pageTitle: translate(`RegisterLinkText`),
        },
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return { conditionalRoutes };
};
